import { notifySuccess } from "../../components/Toast/toast";
import { getErrorFetch } from "../../pages/shared/globalElement";
import { Outlet, useNavigate } from "react-router-dom";
import {
  refreshToken,
  saveTokenInLocalStorage,
} from "../../services/authService";
import {
  changeDemandStatusAndNotifyUser,
  ChangeOperator,
  CloseDemand,
  CloseProcessingDemand,
  DeleteDocument,
  EditDocument,
  getAllDemandsByType,
  getDemand,
  getDemandsByType,
  getProcessingDemandsByType,
  GetRsfDoc,
  RejectDocument,
  ReOpenDemand,
  RsfNotify,
  setDocsAreComplete,
  setDocumentPublic,
  setDueDate,
  SubmitDemand,
  TreatDemand,
  uploadPreuvePostulat,
  ValidateDocument,
} from "../../services/demandService";
import { LOGOUT } from "../reducers/authReducer";
import {
  ADD_DEMAND,
  ADD_DEMANDS,
  ADD_RSF_DATA,
  SET_ERROR,
  SET_FILE,
  SET_FILE_SELECTED,
  SET_IS_DATA_LOADING,
  SET_IS_DELETING,
  SET_IS_OPERATOR_CHANGE,
  SET_IS_PREVIEW,
  SET_IS_REJECTING,
  SET_IS_SAVING_DOC,
  SET_IS_SUBMITTING,
  SET_IS_VALIDATING,
  SET_OPEN_CONFIRM,
  SET_OPEN_DOC,
  CHANGE_STATUS_DEMAND,
  SET_DUE_DATE,
} from "../reducers/demandReducer";

export function EditOpenConfirm(data) {
  return (dispatch) => {
    dispatch({
      type: SET_OPEN_CONFIRM,
      payload: data,
    });
  };
}

export function SetFileSelected(data) {
  return (dispatch) => {
    dispatch({
      type: SET_FILE_SELECTED,
      payload: data,
    });
  };
}

export function SetFile(data) {
  return (dispatch) => {
    dispatch({
      type: SET_FILE,
      payload: data,
    });
  };
}

export function SetOpen(data) {
  return (dispatch) => {
    dispatch({
      type: SET_OPEN_DOC,
      payload: data,
    });
  };
}

export function SetIsPreview(data) {
  return (dispatch) => {
    dispatch({
      type: SET_IS_PREVIEW,
      payload: data,
    });
  };
}

export function getDemands(username, demandType, navigate, token) {
  return (dispatch) => {
    dispatch({
      type: SET_IS_DATA_LOADING,
      payload: true,
    });

    getDemandsByType(username, demandType, token)
      .then((response) => {
        dispatch({
          type: ADD_DEMANDS,
          payload: response.data.demands,
        });
      })
      .catch((error) => {
        if (error.response.status) {
          refreshToken()
            .then((res) => {
              localStorage.removeItem("userDetails");
              saveTokenInLocalStorage(res.data);
            })
            .catch((err) => {
              if (err.response.status) {
                localStorage.removeItem("userDetails");

                dispatch({
                  type: LOGOUT,
                });

                navigate("/security/login");
              }
            });
        } else getErrorFetch(error);
      })
      .finally(() => {
        dispatch({
          type: SET_IS_DATA_LOADING,
          payload: false,
        });
      });
  };
}

export function getAllDemands(demandType, token) {
  return (dispatch) => {
    dispatch({
      type: SET_IS_DATA_LOADING,
      payload: true,
    });

    getAllDemandsByType(demandType, token)
      .then((response) => {
        dispatch({
          type: ADD_DEMANDS,
          payload: response.data.demands,
        });
      })
      .catch((error) => {
        getErrorFetch(error);
      })
      .finally(() => {
        dispatch({
          type: SET_IS_DATA_LOADING,
          payload: false,
        });
      });
  };
}

export function getProcessingDemands(username, demandType, token) {
  return (dispatch) => {
    dispatch({
      type: SET_IS_DATA_LOADING,
      payload: true,
    });

    getProcessingDemandsByType(username, demandType, token)
      .then((response) => {
        dispatch({
          type: ADD_DEMANDS,
          payload: response.data.demands,
        });
      })
      .catch((error) => {
        getErrorFetch(error);
      })
      .finally(() => {
        dispatch({
          type: SET_IS_DATA_LOADING,
          payload: false,
        });
      });
  };
}

export function storeDocument(file, document_id, demand_id, token) {
  return (dispatch) => {
    dispatch({
      type: SET_IS_SAVING_DOC,
      payload: true,
    });

    EditDocument(file, document_id, demand_id, token)
      .then((response) => {
        dispatch({
          type: ADD_DEMAND,
          payload: response.data.demand,
        });
      })
      .catch((error) => {
        getErrorFetch(error);
      })
      .finally(() => {
        dispatch({
          type: SET_IS_SAVING_DOC,
          payload: false,
        });
      });
  };
}

export function processStorePreuvePostulat(file, demand_id, token) {
  return (dispatch) => {
    dispatch({
      type: SET_IS_SUBMITTING,
      payload: true,
    });

    uploadPreuvePostulat(file, demand_id, token)
      .then((response) => {
        dispatch({
          type: ADD_DEMAND,
          payload: response.data.demand,
        });
        notifySuccess("Success");
      })
      .catch((error) => {
        getErrorFetch(error);
      })
      .finally(() => {
        dispatch({
          type: SET_IS_SUBMITTING,
          payload: false,
        });
      });
  };
}

export function processSetDocumentPublic(token) {
  return (dispatch) => {
    dispatch({
      type: SET_IS_SUBMITTING,
      payload: true,
    });

    setDocumentPublic(token)
      .then((response) => {
        notifySuccess("Success");
      })
      .catch((error) => {
        getErrorFetch(error);
      })
      .finally(() => {
        dispatch({
          type: SET_IS_SUBMITTING,
          payload: false,
        });
      });
  };
}

export function processCloseDemand(file, demandId, token) {
  return (dispatch) => {
    dispatch({
      type: SET_IS_SUBMITTING,
      payload: true,
    });

    CloseDemand(file, demandId, token)
      .then((response) => {
        dispatch({
          type: ADD_DEMAND,
          payload: response.data.demand,
        });
        notifySuccess("Success");
      })
      .catch((error) => {
        getErrorFetch(error);
      })
      .finally(() => {
        dispatch({
          type: SET_IS_SUBMITTING,
          payload: false,
        });
      });
  };
}

export function processChangeDemandStatus(demandId, status, token) {
  return (dispatch) => {
    dispatch({
      type: SET_IS_SUBMITTING,
      payload: true,
    });

    changeDemandStatusAndNotifyUser(demandId, status, token)
      .then((response) => {
        dispatch({
          type: CHANGE_STATUS_DEMAND,
          payload: response.data.demand,
        });
        notifySuccess("Success");
      })
      .catch((error) => {
        getErrorFetch(error);
      })
      .finally(() => {
        dispatch({
          type: SET_IS_SUBMITTING,
          payload: false,
        });
      });
  };
}

export function getOneDemand(demand_id, setOperatorSelected, setGetType, setCustomer, token) {
  return (dispatch) => {
    dispatch({
      type: SET_IS_DATA_LOADING,
      payload: true,
    });

    getDemand(demand_id, token)
      .then((response) => {
        dispatch({
          type: ADD_DEMAND,
          payload: response.data.demand,
        });
        setOperatorSelected(response.data.demand.operator);
        setGetType(response.data.demand.demandType);
        setCustomer(response.data.demand.customer);
      })
      .catch((error) => {
        if (error.response.status === 403)
          dispatch({
            type: SET_ERROR,
            payload: error.response.data.error_message,
          });
        else getErrorFetch(error);
      })
      .finally(() => {
        dispatch({
          type: SET_IS_DATA_LOADING,
          payload: false,
        });
      });
  };
}

export function processSubmitDemand(demandNumber, token) {
  return (dispatch) => {
    dispatch({
      type: SET_IS_SUBMITTING,
      payload: true,
    });

    SubmitDemand(demandNumber, token)
      .then((response) => {
        dispatch({
          type: ADD_DEMAND,
          payload: response.data.demand,
        });
        notifySuccess("Success");
      })
      .catch((error) => {
        getErrorFetch(error);
      })
      .finally(() => {
        dispatch({
          type: SET_IS_SUBMITTING,
          payload: false,
        });
      });
  };
}

export function processTreatDemand(demandNumber, username, token) {
  return (dispatch) => {
    dispatch({
      type: SET_IS_SUBMITTING,
      payload: true,
    });

    TreatDemand(demandNumber, username, token)
      .then((response) => {
        dispatch({
          type: ADD_DEMAND,
          payload: response.data.demand,
        });
      })
      .catch((error) => {
        getErrorFetch(error);
      })
      .finally(() => {
        dispatch({
          type: SET_IS_SUBMITTING,
          payload: false,
        });
      });
  };
}

export function processChangeOperator(demandNumber, username, token) {
  return (dispatch) => {
    dispatch({
      type: SET_IS_OPERATOR_CHANGE,
      payload: true,
    });

    ChangeOperator(demandNumber, username, token)
      .then((response) => {
        dispatch({
          type: ADD_DEMAND,
          payload: response.data.demand,
        });
        notifySuccess("Opérateur changé !");
      })
      .catch((error) => {
        getErrorFetch(error);
      })
      .finally(() => {
        dispatch({
          type: SET_IS_OPERATOR_CHANGE,
          payload: false,
        });
      });
  };
}

export function processCloseProcessingDemand(demandNumber, token) {
  return (dispatch) => {
    dispatch({
      type: SET_IS_SUBMITTING,
      payload: true,
    });

    CloseProcessingDemand(demandNumber, token)
      .then((response) => {
        dispatch({
          type: ADD_DEMAND,
          payload: response.data.demand,
        });
        notifySuccess("Success");
      })
      .catch((error) => {
        getErrorFetch(error);
      })
      .finally(() => {
        dispatch({
          type: SET_IS_SUBMITTING,
          payload: false,
        });
      });
  };
}

export function processValidateDocument(demand_id, doc_id, token) {
  return (dispatch) => {
    dispatch({
      type: SET_IS_VALIDATING,
      payload: true,
    });

    ValidateDocument(demand_id, doc_id, token)
      .then((response) => {
        dispatch({
          type: ADD_DEMAND,
          payload: response.data.demand,
        });
      })
      .catch((error) => {
        getErrorFetch(error);
      })
      .finally(() => {
        dispatch({
          type: SET_IS_VALIDATING,
          payload: false,
        });
      });
  };
}

export function processRejectDocument(demand_id, doc_id, raison, token) {
  return (dispatch) => {
    dispatch({
      type: SET_IS_REJECTING,
      payload: true,
    });

    RejectDocument(demand_id, doc_id, raison, token)
      .then((response) => {
        dispatch({
          type: ADD_DEMAND,
          payload: response.data.demand,
        });
      })
      .catch((error) => {
        getErrorFetch(error);
      })
      .finally(() => {
        dispatch({
          type: SET_IS_REJECTING,
          payload: false,
        });
      });
  };
}

export function processDeleteDocument(demand_id, doc_id, token) {
  return (dispatch) => {
    dispatch({
      type: SET_IS_DELETING,
      payload: true,
    });

    DeleteDocument(demand_id, doc_id, token)
      .then((response) => {
        dispatch({
          type: ADD_DEMAND,
          payload: response.data.demand,
        });

        notifySuccess("Document supprimé");
      })
      .catch((error) => {
        getErrorFetch(error);
      })
      .finally(() => {
        dispatch({
          type: SET_IS_DELETING,
          payload: false,
        });
      });
  };
}

export function processRsfNotify(demand_id, token) {
  return (dispatch) => {
    dispatch({
      type: SET_IS_SUBMITTING,
      payload: true,
    });

    RsfNotify(demand_id, token)
      .then((response) => {
        dispatch({
          type: ADD_DEMAND,
          payload: response.data.demand,
        });

        notifySuccess("Utilisateur notifié !");
      })
      .catch((error) => {
        getErrorFetch(error);
      })
      .finally(() => {
        dispatch({
          type: SET_IS_SUBMITTING,
          payload: false,
        });
      });
  };
}

export function processGetRsfDoc(username, token) {
  return (dispatch) => {
    dispatch({
      type: SET_IS_DATA_LOADING,
      payload: true,
    });

    GetRsfDoc(username, token)
      .then((response) => {
        dispatch({
          type: ADD_RSF_DATA,
          payload: response.data.rsf_view,
        });
      })
      .catch((error) => {
        getErrorFetch(error);
      })
      .finally(() => {
        dispatch({
          type: SET_IS_DATA_LOADING,
          payload: false,
        });
      });
  };
}

export function processReOpenDemand(number, token) {
  return (dispatch) => {
    dispatch({
      type: SET_IS_SUBMITTING,
      payload: true,
    });

    ReOpenDemand(number, token)
      .then((response) => {
        dispatch({
          type: ADD_DEMAND,
          payload: response.data.demand,
        });

        notifySuccess("Demande à nouveau disponible en mode modification !");
      })
      .catch((error) => {
        getErrorFetch(error);
      })
      .finally(() => {
        dispatch({
          type: SET_IS_SUBMITTING,
          payload: false,
        });
      });
  };
}

export function processSetDocsAreComplete(demandId, token) {
  return (dispatch) => {
    dispatch({
      type: SET_IS_SUBMITTING,
      payload: true,
    });

    setDocsAreComplete(demandId, token)
      .then((response) => {
        dispatch({
          type: ADD_DEMAND,
          payload: response.data.demand,
        });
        notifySuccess("Statut des documents modifié");
      })
      .catch((error) => {
        getErrorFetch(error);
      })
      .finally(() => {
        dispatch({
          type: SET_IS_SUBMITTING,
          payload: false,
        });
      });
  };
}
