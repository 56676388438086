import { fetch, fetchWithAuthorizationAndFile } from "./api";

export const createSubscription = async (value, token) => {
  return await fetch.post(
    "/demand/subscription/add",
    {
      email: value.email,
      operateur: value.operateur,
      subscription: value.subscription,
      amount: value.amount,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const createAdmin = async (value, token) => {
  return await fetch.post(
    "/admin/add/role",
    {
      username: value.username,
      rolename: value.rolename,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const createClient = async (value, token) => {
  return await fetch.post(
    "/user/client/add",
    {
      firstname: value.name,
      lastname: value.lastname,
      email: value.email,
      birthdate: value.birthdate,
      city: value.city,
      maritalSituation: value.maritalSituation,
      address: value.address,
      phonenumber: value.phone,
      profession: value.profession,
      level: value.level,
      numPassport: value.passeport,
      dateExpirationPassport: value.dateExpiration,
      cni: value.cni,
      immigration: value.immigration,
      child: value.child,
      partner: value.partner,
      bankAccount: value.bankAccount,
      demands: value.demands,
      observations: value.observations,
      id: value.id,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const getAllSubscription = async (token) => {
  return await fetch.get("/demand/subscriptions", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getAllAdmin = async (token) => {
  return await fetch.get("/admin/all", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getAllClient = async (token) => {
  return await fetch.get("/user/client/all", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getUserDetails = async (demandNumber, token) => {
  return await fetch.get("/demand/get/user/details", {
    params: { number: demandNumber },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getUser = async (username, token) => {
  return await fetch.get("/user/one", {
    params: { username: username },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const editUser = async (value, token) => {
  return await fetch.post(
    "/user/client/edit",
    {
      firstname: value.firstname,
      lastname: value.lastname,
      email: value.email,
      birthdate: value.birthdate,
      city: value.city,
      country: value.country,
      address: value.address,
      phonenumber: value.phonenumber,
      genre: value.genre,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const uploadAvatar = async (imageData, token) => {
  return await fetch.post(
    "/user/upload/avatar",
    {
      imageFile: imageData["imageFile"],
      username: imageData["username"],
    },
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const toggleDemandStatus = async (demandNumber, token) => {
  return await fetch.get("/demand/change/status", {
    params: { number: demandNumber },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const toggleDemandDelete = async (demandNumber, token) => {
  const form = new FormData();

  form.append("demand_id", demandNumber);

  return await fetchWithAuthorizationAndFile.post("/demand/delete", form, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const toggleAdminStatus = async (userId, token) => {
  return await fetch.get("/user/change/status", {
    params: { userId },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const deleteAccount = async (userId, token) => {
  return await fetch.get("/user/delete/account", {
    params: { userId },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const deleteClient = async (email, token) => {
  return await fetch.get("/user/client/delete", {
    params: { email },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
