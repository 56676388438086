// assets
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { DocumentScanner } from '@mui/icons-material';

// icons
const icons = { TrackChangesIcon, FactCheckIcon, EditNoteIcon, DocumentScanner };

// ==============================|| MENU ITEMS - UTILITIES ||============================== //

const Business = {
	id: "group-business",
	title: "Business",
	type: "group",
	children: [
		{
			id: "consultation-fees",
			title: "Frais Consultations",
			type: "item",
			url: "/consultation-fees",
			icon: icons.TrackChangesIcon,
		},
		{
			id: "financial-validation",
			title: "Validation Financière",
			type: "item",
			url: "/financial-validation",
			icon: icons.FactCheckIcon,
		},
		{
			id: "financial-input",
			title: "Input Financière",
			type: "item",
			url: "/financial-input",
			icon: icons.EditNoteIcon,
		},
	],
};

export default Business;
