import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Box, Toolbar, useMediaQuery } from "@mui/material";

// project import
import Drawer from "./Drawer/index";
import Header from "./Header/index";
import navigation from "../../menu-items/index";
import Breadcrumbs from "../../components/Breadcrumbs";

// types
import { openDrawer } from "../../hooks/reducers/menuReducer";
import { checkAutoLogin } from "../../services/authService";
import PreviewDocs from "../../pages/demands/PreviewDocs";
import {
  SetFile,
  SetOpen,
  storeDocument,
} from "../../hooks/actions/demandAction";
import {
  SetFile as SetProof,
  SetOpen as SetOpenProof,
  storeProof,
} from "../../hooks/actions/businessAction";
import { processGetUser } from "../../hooks/actions/userAction";

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    checkAutoLogin(dispatch, navigate);
  }, []);

  const {
    DocState: { file, isPreview, openDoc },
    demanNumber,
  } = useSelector((state) => state.demand, shallowEqual);

  const {
    DocState: { file: fileB, isPreview: isPreviewB, openDoc: openDocB },
    currentConsultation,
  } = useSelector((state) => state.business, shallowEqual);

  const { user } = useSelector((state) => state.auth);

  const theme = useTheme();
  const matchDownLG = useMediaQuery(theme.breakpoints.down("xl"));

  const { drawerOpen } = useSelector((state) => state.menu);

  // drawer toggler
  const [open, setOpen] = useState(drawerOpen);
  const handleDrawerToggle = () => {
    setOpen(!open);
    dispatch(openDrawer({ drawerOpen: !open }));
  };

  // set media wise responsive drawer
  useEffect(() => {
    setOpen(!matchDownLG);
    dispatch(openDrawer({ drawerOpen: !matchDownLG }));
  }, [matchDownLG]);

  useEffect(() => {
    if (open !== drawerOpen) setOpen(drawerOpen);
  }, [drawerOpen]);

  useEffect(() => {
    if (user.token) dispatch(processGetUser(user.username, user.token));
  }, [user.token]);

  const handleClose = () => {
    dispatch(SetOpen(false));
    dispatch(SetFile({ ...file, data: undefined, name: "", id: undefined }));
  };

  const handleCloseB = () => {
    dispatch(SetOpenProof(false));
    dispatch(SetProof({ ...fileB, data: undefined, name: "", id: undefined }));
  };

  const handleAccept = () => {
    if (file.id)
      dispatch(
        storeDocument(file.dataToSave, file.id, demanNumber, user.token)
      );
    dispatch(SetOpen(false));
  };

  const handleAcceptB = () => {
    if (fileB.id)
      dispatch(
        storeProof(
          fileB.dataToSave,
          fileB.id,
          currentConsultation.id,
          user.token
        )
      );
    dispatch(SetOpenProof(false));
  };

  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <Header open={open} handleDrawerToggle={handleDrawerToggle} />
      <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
      <Box
        component="main"
        sx={{ width: "100%", flexGrow: 1, p: { xs: 2, sm: 3 } }}
      >
        <Toolbar />
        <Breadcrumbs
          navigation={navigation()}
          title
          titlebottom="true"
          card="false"
          divider={false}
        />
        <Outlet />
      </Box>

      {/* Composant qui permet de visualiser un document 
      On le mets la à cause du fait qu'il soit un composant enfant */}
      <PreviewDocs
        isPreview={isPreview}
        open={openDoc}
        fileName={file.name}
        data={file.data}
        handleClose={handleClose}
        handleAccept={handleAccept}
      />

      <PreviewDocs
        isPreview={isPreviewB}
        open={openDocB}
        fileName={fileB.name}
        data={fileB.data}
        handleClose={handleCloseB}
        handleAccept={handleAcceptB}
      />
    </Box>
  );
};

export default MainLayout;
