// material-ui
import { Box, Typography } from "@mui/material";

// project import
import NavGroup from "./NavGroup";
import menuItem from "../../../../../menu-items/index";

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = () => {
	const navGroups = menuItem().items.map((item) => {
		switch (item.type) {
			case "group":
				return <NavGroup key={item.id} item={item} />;
			default:
				return (
					<Typography key={item.id} variant="h6" color="error" align="center">
						Erreur de navigation
					</Typography>
				);
		}
	});

	return <Box sx={{ pt: 2, bgcolor: "#003f7d" }}>{navGroups}</Box>;
};

export default Navigation;
