import { notifyError, notifySuccess } from "../../components/Toast/toast";
import {
  resendEmailVerification,
  setDueDate,
  setPrice,
  toggleValidClient,
} from "../../services/demandService";
import {
  createAdmin,
  createClient,
  createSubscription,
  deleteAccount,
  deleteClient,
  editUser,
  getAllAdmin,
  getAllClient,
  getAllSubscription,
  getUser,
  getUserDetails,
  toggleAdminStatus,
  toggleDemandDelete,
  toggleDemandStatus,
  uploadAvatar,
} from "../../services/userService";
import {
  ADD_ADMINS,
  ADD_SUBSCRIPTION,
  ADD_SUBSCRIPTIONS,
  ADD_USER_DETAILS,
  DELETE_ADMIN,
  UPDATE_ADMIN,
  SET_IS_ADMIN_DATA_LOADING,
  SET_IS_DELETING,
  SET_IS_EDITING,
  SET_IS_EDITING_STATUS,
  SET_IS_SUBMITTING,
  SET_IS_SUBSCRIPTION_DATA_LOADING,
  SET_IS_USER_DATA_LOADING,
  ADD_ADMIN,
  UPDATE_SUBSCRIPTION,
  ADD_CLIENT,
  SET_IS_CLIENT_DATA_LOADING,
  ADD_CLIENTS,
  UPDATE_CLIENT,
  DELETE_CLIENT,
  ADD_CURRENT_CLIENT,
} from "../reducers/userReducer";
import { REGISTER_ERR_NETWORK, utilsMessage } from "./utilsMessage";

export function processAddSubscription(values, token) {
  return (dispatch) => {
    dispatch({
      type: SET_IS_SUBMITTING,
      payload: true,
    });
    createSubscription(values, token)
      .then((response) => {
        dispatch({
          type: ADD_SUBSCRIPTION,
          payload: response.data.demand,
        });
        notifySuccess("Success");
      })
      .catch((error) => {
        if (error.code === REGISTER_ERR_NETWORK) {
          notifyError(utilsMessage(error.code));
        } else {
          notifyError(error.response.data.error_message);
        }
      })
      .finally(() => {
        dispatch({
          type: SET_IS_SUBMITTING,
          payload: false,
        });
      });
  };
}

export function processGetAllSubscription(token) {
  return (dispatch) => {
    dispatch({
      type: SET_IS_SUBSCRIPTION_DATA_LOADING,
      payload: true,
    });
    getAllSubscription(token)
      .then((response) => {
        dispatch({
          type: ADD_SUBSCRIPTIONS,
          payload: response.data.demands,
        });
      })
      .catch((error) => {
        if (error.code === REGISTER_ERR_NETWORK) {
          notifyError(utilsMessage(error.code));
        } else {
          notifyError(error.response.data.error_message);
        }
      })
      .finally(() => {
        dispatch({
          type: SET_IS_SUBSCRIPTION_DATA_LOADING,
          payload: false,
        });
      });
  };
}

export function processStoreSetDueDate(dueDate, demand_id, token) {
  return (dispatch) => {
    dispatch({
      type: SET_IS_SUBSCRIPTION_DATA_LOADING,
      payload: true,
    });

    setDueDate(dueDate, demand_id, token)
      .then((response) => {
        dispatch({
          type: UPDATE_SUBSCRIPTION,
          payload: response.data.demand,
        });

        notifySuccess("Success");
      })
      .catch((error) => {
        if (error.code === REGISTER_ERR_NETWORK) {
          notifyError(utilsMessage(error.code));
        } else {
          notifyError(error.response.data.error_message);
        }
      })
      .finally(() => {
        dispatch({
          type: SET_IS_SUBSCRIPTION_DATA_LOADING,
          payload: false,
        });
      });
  };
}

export function processStoreSetPrice(demand_id, price, token) {
  return (dispatch) => {
    dispatch({
      type: SET_IS_SUBSCRIPTION_DATA_LOADING,
      payload: true,
    });

    setPrice(demand_id, price, token)
      .then((response) => {
        dispatch({
          type: UPDATE_SUBSCRIPTION,
          payload: response.data.demand,
        });

        notifySuccess("Success");
      })
      .catch((error) => {
        if (error.code === REGISTER_ERR_NETWORK) {
          notifyError(utilsMessage(error.code));
        } else {
          notifyError(error.response.data.error_message);
        }
      })
      .finally(() => {
        dispatch({
          type: SET_IS_SUBSCRIPTION_DATA_LOADING,
          payload: false,
        });
      });
  };
}

export function processResendEmailVerification(email, token) {
  return (dispatch) => {
    dispatch({
      type: SET_IS_SUBSCRIPTION_DATA_LOADING,
      payload: true,
    });

    resendEmailVerification(email, token)
      .then((response) => {
        dispatch({
          type: SET_IS_EDITING,
          payload: true,
        });
        notifySuccess("Le mail de vérification a été envoyé avec succès !");
      })
      .catch((error) => {
        if (error.code === REGISTER_ERR_NETWORK) {
          notifyError(utilsMessage(error.code));
        } else {
          notifyError(error.response.data.error_message);
        }
      })
      .finally(() => {
        dispatch({
          type: SET_IS_SUBSCRIPTION_DATA_LOADING,
          payload: false,
        });
      });
  };
}

export function processGetAllAdmin(token) {
  return (dispatch) => {
    dispatch({
      type: SET_IS_ADMIN_DATA_LOADING,
      payload: true,
    });
    getAllAdmin(token)
      .then((response) => {
        dispatch({
          type: ADD_ADMINS,
          payload: response.data.customers,
        });
      })
      .catch((error) => {
        if (error.code === REGISTER_ERR_NETWORK) {
          notifyError(utilsMessage(error.code));
        } else {
          notifyError(error.response.data.error_message);
        }
      })
      .finally(() => {
        dispatch({
          type: SET_IS_ADMIN_DATA_LOADING,
          payload: false,
        });
      });
  };
}

export function processGetAllClient(token) {
  return (dispatch) => {
    dispatch({
      type: SET_IS_CLIENT_DATA_LOADING,
      payload: true,
    });
    getAllClient(token)
      .then((response) => {
        dispatch({
          type: ADD_CLIENTS,
          payload: response.data.clients,
        });
      })
      .catch((error) => {
        if (error.code === REGISTER_ERR_NETWORK) {
          notifyError(utilsMessage(error.code));
        } else {
          notifyError(error.response.data.error_message);
        }
      })
      .finally(() => {
        dispatch({
          type: SET_IS_CLIENT_DATA_LOADING,
          payload: false,
        });
      });
  };
}

export function processCreateAdmin(values, token) {
  return (dispatch) => {
    dispatch({
      type: SET_IS_SUBMITTING,
      payload: true,
    });
    createAdmin(values, token)
      .then((response) => {
        dispatch({
          type: ADD_ADMIN,
          payload: response.data.customer,
        });
        notifySuccess("Ajouté !");
      })
      .catch((error) => {
        if (error.code === REGISTER_ERR_NETWORK) {
          notifyError(utilsMessage(error.code));
        } else {
          notifyError(error.response.data.error_message);
        }
      })
      .finally(() => {
        dispatch({
          type: SET_IS_SUBMITTING,
          payload: false,
        });
      });
  };
}

export function processCreateClient(values, editing, token) {
  return (dispatch) => {
    dispatch({
      type: SET_IS_SUBMITTING,
      payload: true,
    });
    createClient(values, token)
      .then((response) => {
        if (editing) {
          dispatch({
            type: UPDATE_CLIENT,
            payload: response.data.client,
          });
          notifySuccess("Ajouté !");
        } else {
          dispatch({
            type: ADD_CLIENT,
            payload: response.data.client,
          });
          notifySuccess("Ajouté !");
        }
      })
      .catch((error) => {
        if (error.code === REGISTER_ERR_NETWORK) {
          notifyError(utilsMessage(error.code));
        } else {
          notifyError(error.response.data.error_message);
        }
      })
      .finally(() => {
        dispatch({
          type: SET_IS_SUBMITTING,
          payload: false,
        });
      });
  };
}

export function processGetUserDetails(demandNumber, token) {
  return (dispatch) => {
    dispatch({
      type: SET_IS_USER_DATA_LOADING,
      payload: true,
    });
    getUserDetails(demandNumber, token)
      .then((response) => {
        dispatch({
          type: ADD_USER_DETAILS,
          payload: response.data.user_details,
        });
      })
      .catch((error) => {
        if (error.code === REGISTER_ERR_NETWORK) {
          notifyError(utilsMessage(error.code));
        } else {
          notifyError(error.response.data.error_message);
        }
      })
      .finally(() => {
        dispatch({
          type: SET_IS_USER_DATA_LOADING,
          payload: false,
        });
      });
  };
}

export function processToggleDemandStatus(demandNumber, token) {
  return (dispatch) => {
    dispatch({
      type: SET_IS_EDITING,
      payload: true,
    });
    toggleDemandStatus(demandNumber, token)
      .then((response) => {
        dispatch({
          type: ADD_SUBSCRIPTIONS,
          payload: response.data.demand,
        });
        notifySuccess(
          "Le status de la demande " + demandNumber + " a changé !"
        );
      })
      .catch((error) => {
        if (error.code === REGISTER_ERR_NETWORK) {
          notifyError(utilsMessage(error.code));
        } else {
          notifyError(error.response.data.error_message);
        }
      })
      .finally(() => {
        dispatch({
          type: SET_IS_EDITING,
          payload: false,
        });
      });
  };
}

export function deleteDemand(demandNumber, token) {
  return (dispatch) => {
    dispatch({
      type: SET_IS_DELETING,
      payload: true,
    });
    toggleDemandDelete(demandNumber, token)
      .then((response) => {
        dispatch({
          type: SET_IS_EDITING,
          payload: true,
        });
        notifySuccess("La demande a été supprimé avec succès !");
      })
      .catch((error) => {
        if (error.code === REGISTER_ERR_NETWORK) {
          notifyError(utilsMessage(error.code));
        } else {
          notifyError(error.response.data.error_message);
        }
      })
      .finally(() => {
        dispatch({
          type: SET_IS_EDITING,
          payload: false,
        });
      });
  };
}

export function processToggleAdminStatus(userId, token) {
  return (dispatch) => {
    dispatch({
      type: SET_IS_EDITING_STATUS,
      payload: true,
    });
    toggleAdminStatus(userId, token)
      .then((response) => {
        dispatch({
          type: UPDATE_ADMIN,
          payload: response.data.customer,
        });
        notifySuccess("Le statut a changé !");
      })
      .catch((error) => {
        if (error.code === REGISTER_ERR_NETWORK) {
          notifyError(utilsMessage(error.code));
        } else {
          notifyError(error.response.data.error_message);
        }
      })
      .finally(() => {
        dispatch({
          type: SET_IS_EDITING_STATUS,
          payload: false,
        });
      });
  };
}

export function processToggleValidClient(email, status, operator, token) {
  return (dispatch) => {
    dispatch({
      type: SET_IS_EDITING_STATUS,
      payload: true,
    });
    toggleValidClient(email, status, operator, token)
      .then((response) => {
        dispatch({
          type: UPDATE_CLIENT,
          payload: response.data.client,
        });
      })
      .catch((error) => {
        if (error.code === REGISTER_ERR_NETWORK) {
          notifyError(utilsMessage(error.code));
        } else {
          notifyError(error.response.data.error_message);
        }
      })
      .finally(() => {
        dispatch({
          type: SET_IS_EDITING_STATUS,
          payload: false,
        });
      });
  };
}

export function processDeleteAccount(userId, token) {
  return (dispatch) => {
    dispatch({
      type: SET_IS_DELETING,
      payload: true,
    });
    deleteAccount(userId, token)
      .then((response) => {
        dispatch({
          type: DELETE_ADMIN,
          payload: response.data.customer,
        });
        notifySuccess("Supprimé !");
      })
      .catch((error) => {
        if (error.code === REGISTER_ERR_NETWORK) {
          notifyError(utilsMessage(error.code));
        } else {
          notifyError(error.response.data.error_message);
        }
      })
      .finally(() => {
        dispatch({
          type: SET_IS_DELETING,
          payload: false,
        });
      });
  };
}

export function processDeleteClient(email, token) {
  return (dispatch) => {
    dispatch({
      type: SET_IS_DELETING,
      payload: true,
    });
    deleteClient(email, token)
      .then((response) => {
        dispatch({
          type: DELETE_CLIENT,
          payload: response.data.client,
        });
        notifySuccess("Supprimé !");
      })
      .catch((error) => {
        if (error.code === REGISTER_ERR_NETWORK) {
          notifyError(utilsMessage(error.code));
        } else {
          notifyError(error.response.data.error_message);
        }
      })
      .finally(() => {
        dispatch({
          type: SET_IS_DELETING,
          payload: false,
        });
      });
  };
}

export function AddCurrentClient(data) {
  return (dispatch) => {
    dispatch({
      type: ADD_CURRENT_CLIENT,
      payload: data,
    });
  };
}

export function processGetUser(username, token) {
  return (dispatch) => {
    dispatch({
      type: SET_IS_USER_DATA_LOADING,
      payload: true,
    });
    getUser(username, token)
      .then((response) => {
        dispatch({
          type: ADD_CURRENT_CLIENT,
          payload: response.data.client,
        });
      })
      .catch((error) => {
        if (error.code === REGISTER_ERR_NETWORK) {
          notifyError(utilsMessage(error.code));
        } else {
          notifyError(error.response.data.error_message);
        }
      })
      .finally(() => {
        dispatch({
          type: SET_IS_USER_DATA_LOADING,
          payload: false,
        });
      });
  };
}

export function processEditProfile(values, token) {
  return (dispatch) => {
    dispatch({
      type: SET_IS_USER_DATA_LOADING,
      payload: true,
    });
    editUser(values, token)
      .then((response) => {
        dispatch({
          type: ADD_CURRENT_CLIENT,
          payload: response.data.client,
        });
        notifySuccess("Modifiée !");
      })
      .catch((error) => {
        if (error.code === REGISTER_ERR_NETWORK) {
          notifyError(utilsMessage(error.code));
        } else {
          notifyError(error.response.data.error_message);
        }
      })
      .finally(() => {
        dispatch({
          type: SET_IS_USER_DATA_LOADING,
          payload: false,
        });
      });
  };
}

export function processUploadAvatar(imageData, token) {
  return (dispatch) => {
    dispatch({
      type: SET_IS_USER_DATA_LOADING,
      payload: true,
    });
    uploadAvatar(imageData, token)
      .then((response) => {
        dispatch({
          type: ADD_CURRENT_CLIENT,
          payload: response.data.client,
        });
        notifySuccess("Modifiée !");
      })
      .catch((error) => {
        if (error.code === REGISTER_ERR_NETWORK) {
          notifyError(utilsMessage(error.code));
        } else {
          notifyError(error.response.data.error_message);
        }
      })
      .finally(() => {
        dispatch({
          type: SET_IS_USER_DATA_LOADING,
          payload: false,
        });
      });
  };
}
