import { lazy } from "react";
import Loadable from "../components/loader/Loadable";
import MinimalLayout from "../layout/MinimalLayout/index";

// Render
const AuthLogin = Loadable(lazy(() => import("../pages/account/Login")));

const AuthDefinePassword = Loadable(
  lazy(() => import("../pages/account/DefinePassword"))
);

const AuthChangePassword = Loadable(
  lazy(() => import("../pages/account/ResetPassword"))
);

const AuthRegister = Loadable(lazy(() => import("../pages/account/Register")));

const AuthResetPassword = Loadable(
  lazy(() => import("../pages/account/ResetPasswordForm"))
);

const AuthVerifyEmail = Loadable(
  lazy(() => import("../pages/account/ValidateEmail"))
);

// Routing
const LoginRoutes = {
  path: "/security",
  element: <MinimalLayout />,
  children: [
    {
      path: "login",
      element: <AuthLogin />,
    },
    {
      path: "define-password",
      element: <AuthDefinePassword />,
    },
    {
      path: "complete-registration",
      element: <AuthRegister />,
    },
    {
      path: "password-reset",
      element: <AuthResetPassword />,
    },
    {
      path: "change-password",
      element: <AuthChangePassword />,
    },
    {
      path: "verify-email",
      element: <AuthVerifyEmail />,
    },
  ],
};

export default LoginRoutes;
